<qt-network-error [error]="networkError$ | async"></qt-network-error>
<div
  *ngIf="{
    email: email$ | async,
    emailIsEditable: emailIsEditable$ | async,
    termsAndPrivacy: getTermsAndPrivacyLinks$ | async,
    firstName: firstName$ | async,
  } as resolved"
  class="mfa-login__container">
  <div class="mfa-login__logo-container">
    <img class="mfa-login__logo" src="assets/img/logo.svg" alt="logo" />
    <span class="mfa-login__logo-text mat-headline-4">{{
      'GNR_SYNPLY' | translate
    }}</span>
  </div>

  <ng-container [ngSwitch]="currentStep">
    <qt-login-email
      *ngSwitchCase="LoginSteps.EMAIL"
      [email]="resolved.email"
      [emailIsEditable]="resolved.emailIsEditable ?? true"
      [name]="name"
      [phn]="phn"
      [promo]="promo"
      [token]="token$ | async"
      [ptl]="PortalType.ADMIN"
      (lostPasswordlick)="handleSubmitLostPassword()"
      (submitClick)="handleSubmitEmail($event)"></qt-login-email>

    <qt-login-password
      *ngSwitchCase="LoginSteps.PASSWORD"
      [email]="resolved.email ?? ''"
      [validationError]="(validationErrorMessage$ | async) ?? undefined"
      [triggerMfaCode$]="triggerMfaCode$"
      [stepSubject]="triggerChangeStepSubject"
      (lostPasswordClick)="handleSubmitLostPassword()"
      (submitClick)="handleSubmitPassword($event)"
      (submitMfaCodeClick)="handleSubmitMfaCode($event)"></qt-login-password>

    <qt-login-reset-password
      *ngSwitchCase="LoginSteps.RESET_PASSWORD"
      [email]="resolved.email"
      [stepSubject]="triggerChangeStepSubject"
      (submitClick)="
        handleSubmitRememberPassword($event)
      "></qt-login-reset-password>

    <qt-login-reset-password-confirm
      *ngSwitchCase="LoginSteps.RESET_PASSWORD_CONFIRM"
      [email]="resolved.email"
      (submitClick)="
        handleSubmitResetPassword($event)
      "></qt-login-reset-password-confirm>

    <qt-login-register
      *ngSwitchCase="LoginSteps.REGISTER"
      [email]="resolved.email ?? undefined"
      [emailDisabled]="!!resolved.email"
      [termsAndPrivacy]="resolved.termsAndPrivacy"
      [phn]="phn"
      [promo]="promo"
      [ptl]="PortalType.ADMIN"
      [token]="token$ | async"
      (submitClick)="handleSubmitRegister($event)"></qt-login-register>
  </ng-container>

  <div class="mfa-login__footer-container">
    <mat-form-field
      *ngIf="{
        selectedLanguage: getSelectedLanguage$ | async,
        languages: getLanguages$ | async,
      } as resolved"
      style="font-size: 12px"
      appearance="outline"
      subscriptSizing="dynamic">
      <mat-select
        class="mfa-login__languages"
        [ngModel]="resolved.selectedLanguage"
        (ngModelChange)="handleChangeLanguage($event)">
        <mat-select-trigger>
          <div class="mfa-login__languages-selected-language">
            <mat-icon>language</mat-icon>
            {{
              findLanguageName(resolved.selectedLanguage, resolved.languages)
            }}
          </div>
        </mat-select-trigger>
        <mat-option
          *ngFor="let language of resolved.languages"
          [value]="language.id">
          {{ language.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="mfa-login__terms-container">
      <a mat-button color="link" [href]="resolved.termsAndPrivacy.terms">{{
        'GNR_TERMS' | translate
      }}</a>
      <a mat-button color="link" [href]="resolved.termsAndPrivacy.privacy">{{
        'GNR_PRIVACY' | translate
      }}</a>
    </div>
  </div>
</div>
