import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { TranslationCoreModule } from '@qtek/libs/translation-core';
import { WSAlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { WsErrorHandlerService } from './ws-error-handler.service';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatExpansionModule,
    TranslationCoreModule,
    MatListModule,
    MatButtonModule,
  ],
  providers: [WsErrorHandlerService],
  declarations: [WSAlertDialogComponent],
})
export class WsErrorHandlerModule {}
