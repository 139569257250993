import { AsyncPipe, DOCUMENT, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { VERSION, environment } from '@env';
import { Store } from '@ngrx/store';
import { ApiCoreModule, ApiCoreService } from '@qtek/core/api-core';
import {
  LocalStorageManagementFeature,
  LocalStorageManagementModule,
  LocalStorageManagementService,
} from '@qtek/core/local-storage-management';
import {
  ThemeManagementModule,
  ThemeManagementService,
} from '@qtek/core/theme-management';
import {
  WebSocketService,
  WebsocketsCoreModule,
} from '@qtek/core/websockets-core';
import {
  HealthcheckCoreModule,
  HealthcheckCoreService,
} from '@qtek/libs/healthcheck-core';
import {
  MetaCoreActions,
  MetaCoreFeature,
  MetaCoreModule,
} from '@qtek/libs/meta-core';
import { TranslationCoreModule } from '@qtek/libs/translation-core';
import {
  WsErrorHandlerModule,
  WsErrorHandlerService,
} from '@qtek/libs/ws-error-handler';
import { isNonNullable } from '@qtek/shared/utils';
import { distinctUntilChanged, filter, of, take, tap } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    ReactiveFormsModule,
    MatInputModule,
    AsyncPipe,
    LocalStorageManagementModule,
    ThemeManagementModule,
    WebsocketsCoreModule,
    TranslationCoreModule,
    HealthcheckCoreModule,
    NgIf,
    ApiCoreModule,
    MetaCoreModule,
    WsErrorHandlerModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'login-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  theme$ = this.store
    .select(LocalStorageManagementFeature.selectUiTheme)
    .pipe(distinctUntilChanged());

  constructor(
    private store: Store,
    private localStorageManagementService: LocalStorageManagementService,
    private themeManagementService: ThemeManagementService,
    private webSocketService: WebSocketService,
    private healthcheckCoreService: HealthcheckCoreService,
    private apiCoreService: ApiCoreService,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document,
    private wsErrorHandler: WsErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.webSocketService.initWebsocketConnection(
      this.store
        .select(MetaCoreFeature.selectWsPingPong)
        .pipe(filter(isNonNullable)),
      this.wsErrorHandler.errorsHandler.bind(this.wsErrorHandler)
    );
    this.healthcheckCoreService.registerCheckHealthSubscription(
      this.store
        .select(MetaCoreFeature.selectWsPingPong)
        .pipe(filter(isNonNullable)),
      of('/api/v1/service/meta')
    );

    this.store.dispatch(MetaCoreActions.loadMetaGuiOnlineBookDomain());
    this.store.dispatch(MetaCoreActions.loadMetaLanguages());
    this.store.dispatch(MetaCoreActions.loadMetaWebsocket());
    this.store.dispatch(MetaCoreActions.loadMetaColors());

    // replace material icons with symbols font
    const defaultFontSetClasses = this.matIconRegistry.getDefaultFontSetClass();
    const outlinedFontSetClasses = defaultFontSetClasses
      .filter(fontSetClass => fontSetClass !== 'material-icons')
      .concat(['material-symbols-outlined']);
    this.matIconRegistry.setDefaultFontSetClass(...outlinedFontSetClasses);
    this.store
      .select(MetaCoreFeature.selectGuiDomain)
      .pipe(
        filter(isNonNullable),
        take(1),
        tap(domain => {
          this.registerIcons(domain);
        })
      )
      .subscribe();
  }

  private registerIcons(domain: string): void {
    let iconsPath: string;

    if (environment.production) {
      iconsPath = `${domain}/assets/img/icons/icons.svg?v=${VERSION.full}`;
    } else {
      const { host } = this.document.location;
      iconsPath = `//${host}/assets/img/icons/icons.svg?v=${VERSION.full}`;
    }

    const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(iconsPath);

    this.matIconRegistry.addSvgIconSet(safeUrl);
  }
}
