import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HTTP_BASE_URL } from '@qtek/core/api-core';
import { WebSocketService } from '@qtek/core/websockets-core';
import { OnlineService } from '@qtek/shared/services';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  interval,
  map,
  merge,
  mergeMap,
  Observable,
  retry,
  skip,
  startWith,
  Subject,
  Subscription,
  switchMap,
  take,
  tap,
  timer,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HealthcheckCoreService {
  private readonly isHealthyHttpConnectionSubject = new BehaviorSubject(true);
  public readonly isHealthyHttpConnection$ = this.isHealthyHttpConnectionSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  public readonly isWebSocketAlive$ =
    this.webSocketService.getWebSocketIsAlive$();
  private isHealthyHttpConnectionSubscription: Subscription | null = null;
  private retryDelay = (interval: number) => {
    this.isHealthyHttpConnectionSubject.next(false);
    return timer(interval);
  };
  readonly isHealthy$ = combineLatest([
    this.isHealthyHttpConnection$,
    this.isWebSocketAlive$,
  ]).pipe(
    map(
      ([isHealthyHttpConnection, isAlive]) => isHealthyHttpConnection && isAlive
    ),
    distinctUntilChanged(),
    debounceTime(250)
  );

  private checkHttp$ = new Subject<void>();

  constructor(
    @Inject(HTTP_BASE_URL) private httpBaseUrl: string,
    private webSocketService: WebSocketService,
    private onlineService: OnlineService,
    private httpClient: HttpClient
  ) {}

  registerCheckHealthSubscription(
    interval$: Observable<number>,
    endpoint$: Observable<string>
  ): void {
    this.onlineService
      .change()
      .pipe(
        distinctUntilChanged(),
        filter(Boolean),
        skip(1),
        tap(() => {
          this.webSocketService.reconnect();
          this.checkHttp$.next();
        })
      )
      .subscribe();

    this.isHealthyHttpConnectionSubscription?.unsubscribe();
    this.isHealthyHttpConnectionSubscription = combineLatest([
      interval$.pipe(startWith(15)),
      endpoint$,
    ])
      .pipe(
        take(1),
        mergeMap(([intervalValue, endpoint]) => {
          return merge(
            interval((intervalValue ?? 15) * 1000),
            this.checkHttp$
          ).pipe(
            switchMap(() =>
              this.httpClient.head(`${this.httpBaseUrl}${endpoint}`)
            ),
            retry({
              resetOnSuccess: true,
              delay: () => this.retryDelay(intervalValue),
            })
          );
        }),
        catchError(error => {
          this.isHealthyHttpConnectionSubject.next(false);
          console.error(
            'There is a problem with connection to Backend please try refresh app, if problems persist please contact your support',
            error
          );
          return EMPTY;
        }),
        tap(() => {
          this.isHealthyHttpConnectionSubject.next(true);
        })
      )
      .subscribe();
  }

  public setHttpUnhealthy() {
    this.isHealthyHttpConnectionSubject.next(false);
  }
}
