import {
  ChangeDetectorRef,
  Inject,
  OnDestroy,
  Optional,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  first,
  mergeMap,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';

import { DebugTranslationsDI } from '../models/translation-core.model';
import { TranslationCoreService } from '../services/translation-core.service';
import { TranslationCoreFeature } from '../store/translation-core.reducer';

@Pipe({
  name: 'translate',
  pure: false,
})
export class TranslateCorePipe implements PipeTransform, OnDestroy {
  private readonly translationKeySubject = new BehaviorSubject('');
  private readonly translationKey$ = this.translationKeySubject
    .asObservable()
    .pipe(distinctUntilChanged());
  private readonly translationParamsSubject = new BehaviorSubject<
    (string | number | string[])[]
  >([]);
  private readonly translationParams$ =
    this.translationParamsSubject.asObservable();
  private readonly translatedValueSubject = new BehaviorSubject<string>('');
  private readonly translatedValue$ = this.translatedValueSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private destroy$ = new Subject<void>();

  private translatedValue = '';

  constructor(
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef,
    private translationCoreService: TranslationCoreService,
    @Optional() @Inject(DebugTranslationsDI) private debugTranslation: boolean
  ) {
    combineLatest([
      this.translationKey$,
      this.translationParams$,
      this.store.select(TranslationCoreFeature.selectLang),
    ])
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(0),
        mergeMap(([key, params, lang]) => {
          if (key === '') {
            return EMPTY;
          }

          return this.translationCoreService.getTranslation(key, {
            lang,
            params,
          });
        }),
        tap(value => {
          this.updateTranslateValue(value);
        }),
        takeUntilDestroyed()
      )
      .subscribe();

    this.translatedValue$
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(value => {
        this.translatedValue = value;
        this.changeDetectorRef.markForCheck();
      });
  }

  transform(
    key: string | number | undefined,
    params?: (string | number | string[])[],
    fallback?: string
  ): string {
    if (typeof key === 'number' || !key || !key.length) {
      return String(key);
    }
    let noTranslation = '';
    if (this.debugTranslation || fallback) {
      noTranslation = fallback || key;
    }
    this.translationKeySubject.next(key);
    this.translationParamsSubject.next(params ?? []);
    this.translatedValueSubject.next(noTranslation);

    // console.log('start translating', moment().format('HH:mm:ss:SSS'), key);
    this.translationCoreService
      .getTranslationFromCache(key, { params })
      .pipe(
        first(),
        tap(translation => {
          this.translatedValueSubject.next(translation);
          // console.log('end translating', moment().format('HH:mm:ss:SSS'), key);
        })
      )
      .subscribe();

    return this.translatedValue;
  }

  private updateTranslateValue(value?: string | null): void {
    if (!value && this.debugTranslation) {
      console.warn(
        '[Translations] No translation for - ' +
          this.translationKeySubject.getValue()
      );
    } else {
      this.translatedValueSubject.next(
        value ? value : this.translationKeySubject.getValue()
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
