import { ENTER } from '@angular/cdk/keycodes';
import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { Subject, filter, startWith, take, takeUntil } from 'rxjs';

@Directive({
  selector: '[qtSubmitIfValid]',
  standalone: true,
})
export class SubmitIfValidDirective implements OnDestroy {
  @Input() qtSubmitOnEnter = true;
  @Input('qtSubmitIfValid') formRef: FormGroup | FormControl;
  @Input() qtSubmitIfDisabled = true;
  @Output('qtSubmitIfValid') submitValue = new EventEmitter<boolean>();

  @HostListener('click')
  handleClick() {
    this.validateAllFormFields(this.formRef);
    this.submitIfValid();
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyPress(event: KeyboardEvent) {
    if (
      this.qtSubmitOnEnter &&
      (event.keyCode === ENTER || event.key === 'Enter')
    ) {
      event.preventDefault();

      this.matButton?._elementRef.nativeElement.click();
    }
  }

  destroySubject = new Subject<void>();
  destroy$ = this.destroySubject.asObservable();

  constructor(private matButton: MatButton) {}

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  private submitIfValid(): void {
    this.formRef.statusChanges
      .pipe(
        startWith(this.formRef.status),
        filter(status => status !== 'PENDING'),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(status => {
        if (
          status === 'VALID' ||
          (this.qtSubmitIfDisabled && status === 'DISABLED')
        ) {
          this.submitValue.emit(true);
        } else {
          this.submitValue.emit(false);
        }
      });
  }

  private validateAllFormFields(formRef: AbstractControl): void {
    if (formRef instanceof FormGroup) {
      Object.values(formRef.controls).forEach(control => {
        this.validateAllFormFields(control);
      });
    } else if (formRef instanceof FormControl) {
      formRef.updateValueAndValidity();
      formRef.markAsTouched();
      formRef.markAsDirty();
    } else if (formRef instanceof FormArray) {
      for (const group of formRef.controls) {
        this.validateAllFormFields(group);
      }
    }
  }
}
