<div class="wrapper">
  <mat-icon class="icon"> signal_wifi_statusbar_not_connected </mat-icon>
  <span class="accent">
    {{ 'ERR_NET_TITLE' | translate: [] : ErrNetTitleText }}:
  </span>
  <ng-container>
    <ng-container [ngSwitch]="error">
      <span *ngSwitchCase="'ws'">
        {{ 'ERR_NET_COMMON' | translate: [] : ErrNetCommonText }}.
        {{ 'ERR_NET_WS' | translate: [] : ErrNetWSText }}
      </span>
      <span *ngSwitchCase="'http'">
        {{ 'ERR_NET_COMMON' | translate: [] : ErrNetCommonText }}.
        {{ 'ERR_NET_HTTP' | translate: [] : ErrNetHttpText }}
      </span>
      <span *ngSwitchCase="'offline'">
        {{ 'ERR_NET_COMMON' | translate: [] : ErrNetCommonText }}.
        {{ 'ERR_OFFLINE' | translate: [] : ErrNetOfflineText }}
      </span>
      <span *ngSwitchDefault>
        {{ 'ERR_NET_COMMON' | translate: [] : ErrNetCommonText }}
      </span>
    </ng-container>
  </ng-container>
</div>
