import { ComponentType } from '@angular/cdk/overlay';
import {
  Injectable,
  Injector,
  NgModuleRef,
  OnDestroy,
  Type,
  createNgModule,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  ActivatedRouteSnapshot,
  ROUTES,
  Route,
  Router,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import {
  Observable,
  Subscription,
  from,
  isObservable,
  of,
  throwError,
} from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { DialogName } from '../dialogs.config';
import { ActionData, ActionLinkObserver } from './action-link-observer.service';

interface LoadedDialog<T> {
  component: ComponentType<any>;
  config: MatDialogConfig;
  data: T;
}

/** Lazy Dialog Module Loader. Use this class as a canActivate guard within a regular lazy loading route. */
@Injectable({
  providedIn: 'root',
})
export class LazyDialogLoader extends ActionLinkObserver implements OnDestroy {
  private dialogs = new Map<string, Route>();

  private readonly sub: Subscription;
  // private navigationEnd$ = new Subject<void>();

  constructor(
    router: Router,
    private injector: Injector,
    private dialogService: MatDialog
  ) {
    super(router);

    /** Builds the dialogs stream */
    this.sub = this.observers$
      .pipe(
        switchMap(({ route, state }) => {
          // Extracts the dialog input data from the query parameters
          const data = this.actionData(route);

          const { loadComponent } = route.routeConfig;

          if (loadComponent) {
            return this.loadDialogByStandalone<ActionData>(
              route.routeConfig,
              data,
              route,
              state
            )
              .pipe(
                switchMap(({ component, config, data }) =>
                  this.openDialogComponent<ActionData, any>(
                    component,
                    config,
                    data
                  )
                )
              )
              .toPromise();
          } else {
            return this.loadDialogByModule<ActionData>(
              route.routeConfig,
              data,
              route,
              state
            )
              .pipe(
                switchMap(({ component, config, data }) =>
                  this.openDialogComponent<ActionData, any>(
                    component,
                    config,
                    data
                  )
                )
              )
              .toPromise();
          }
        })
      )
      .subscribe(value => console.log('Dialog closed returning', value));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /** Activate a dialog programmatically */
  public open<T, R>(dialog: DialogName, data?: T): Observable<R> {
    // Seeks for the requested dialog within the routes
    const routeConfig = this.seekDialog(dialog);

    if (!routeConfig) {
      return throwError(
        () =>
          new Error(`
      Unable to find the requested dialog "${dialog}".
      Make sure the corresponding Route exists within the same module this DialogLoader instance is provided.
    `)
      );
    }

    const { loadComponent } = routeConfig;

    if (loadComponent) {
      return this.loadDialogByStandalone<T>(routeConfig, data).pipe(
        switchMap(({ component, config, data }) =>
          this.openDialogComponent<T, R>(component, config, data)
        )
      );
    } else {
      return this.loadDialogByModule<T>(routeConfig, data).pipe(
        switchMap(({ component, config, data }) =>
          this.openDialogComponent<T, R>(component, config, data)
        )
      );
    }
  }

  public preloadDialog(dialog: DialogName) {
    // Seeks for the requested dialog within the routes
    const routeConfig = this.seekDialog(dialog);

    if (!routeConfig) {
      return throwError(
        () =>
          new Error(`
      Unable to find the requested dialog "${dialog}".
      Make sure the corresponding Route exists within the same module this DialogLoader instance is provided.
    `)
      );
    }

    const { loadComponent } = routeConfig;

    if (loadComponent) {
      return this.loadDialogByStandalone(routeConfig).pipe(first()).subscribe();
    } else {
      return this.loadDialogByModule(routeConfig).pipe(first()).subscribe();
    }
}

  private loadDialogByStandalone<T>(
    routeConfig: Route,
    data?: T,
    route?: ActivatedRouteSnapshot,
    state?: RouterStateSnapshot
  ): Observable<LoadedDialog<T>> {
    const module: NgModuleRef<any> = (routeConfig as any)._loadedConfig?.module;
    if (module) {
      // Extracts the internal routes too
      const routes: Routes = (routeConfig as any)?._loadedConfig?.routes || [];

      // Returns the config data already loaded by the Router
      // return of({ module, routes });
    }

    return from(
      routeConfig.loadComponent() as Promise<ComponentType<any>>
    ).pipe(
      map(component => {
        const config: MatDialogConfig = routeConfig.data?.['dialogConfig'];

        return { component, config, data };
      })
    );
  }

  /** Lazily loads the dialog mimicking the Router from the given routeConfig */
  private loadDialogByModule<T>(
    routeConfig: Route,
    data?: T,
    route?: ActivatedRouteSnapshot,
    state?: RouterStateSnapshot
  ): Observable<LoadedDialog<T>> {
    // Loads the route configuration first
    return this.loadRouteConfig(routeConfig).pipe(
      map(({ module, routes }) => {
        // Seeks for the primary child route where to find the dialog component
        const root = routes.find(({ path }) => path === '');

        // Gets the dialog component
        const component = root?.component;
        if (!component) {
          throw new Error(`
            Unable to find the dialog component within the module's routes.
            Make sure your dialog module exports a root Route with an empty path as if it were a regular lazely loaded routing module.
          `);
        }

        // Extracts the dialog configuration from the route, if any
        const config: MatDialogConfig = root.data?.['dialogConfig'];

        // Opens the dialog according to the given parameters
        return { component, config, data };
      })
    );
  }

  private openDialogComponent<T, R>(
    component: ComponentType<any>,
    config: MatDialogConfig,
    data: T
  ): Observable<R> {
    return this.dialogService
      .open<unknown, T, R>(component, { ...config, data })
      .afterClosed()
      .pipe(first());
  }

  /** Loads the Route config */
  private loadRouteConfig(
    routeConfig: Route
  ): Observable<{ module: NgModuleRef<any>; routes: Routes }> {
    // Extracts the internal NgModule ref eventually already lazily loaded by the Router
    const module: NgModuleRef<any> = (routeConfig as any)._loadedConfig?.module;
    if (module) {
      // Extracts the internal routes too
      const routes: Routes = (routeConfig as any)?._loadedConfig?.routes || [];

      // Returns the config data already loaded by the Router
      return of({ module, routes });
    }

    // Gets the loader function otherwise
    const loader = routeConfig.loadChildren;
    if (!loader || typeof loader !== 'function') {
      return throwError(
        new Error(`
      The matching Route "${routeConfig.path}" misses the proper loadChildren function.
    `)
      );
    }

    // Loads the module file
    return from((loader as () => Promise<Type<any>>)()).pipe(
      // // Compiles the module
      // switchMap((moduleType) => {
      //   // Gets the compiler
      //   const compiler = this.injector.get(Compiler);

      //   // Compiles the module asyncronously
      //   return compiler.compileModuleAsync(moduleType);
      // }),

      map(moduleType => {
        // Creates the module from the module factory
        const module = createNgModule(moduleType, this.injector);

        // Returns the module ref with the associated flatten routes array
        return { module, routes: this.routes(module) };
      }),

      // Saves the loaded config within the route mimicking the Router
      tap(config => ((routeConfig as any)._loadedConfig = config))
    );
  }

  /** Seeks for the requested dialog route configuration */
  private seekDialog(dialog: string): Route {
    // Checks within the cached values first
    const route = this.dialogs.get(dialog);
    if (route) {
      return route;
    }

    const parseTree: any = (dialog: string, match: Route, routes: Routes) => {
      // Parses the router configuration tree otherwise
      return routes.reduce((match, route) => {
        // Recurs down the children
        if ('children' in route) {
          return parseTree(dialog, match, route.children);
        }

        // Recurs down the lazily loaded modules
        if ('_loadedConfig' in route) {
          return parseTree(
            dialog,
            match,
            (route as any)._loadedConfig.routes || []
          );
        }

        // Does something only when the LazyDailogLoader is there
        if (route.canActivate?.indexOf(LazyDialogLoader) >= 0) {
          // Stores the dialog in the cache for the future
          this.dialogs.set(route.path, route);

          // Returns the ,matching value
          if (route.path === dialog) {
            return route;
          }
        }

        return match;
      }, match);
    };

    return parseTree(dialog, undefined, this.router.config);
  }

  /** Retrives the Routes array for the given Module defaulting to the current Module when not specified */
  private routes(module?: NgModuleRef<any>): Routes {
    // Injects the Routes (note multi is set to true)
    const routes = (module?.injector || this.injector).get(ROUTES, []);

    // Flattens the routes array
    return Array.prototype.concat.apply([], routes);
  }

  /** Asses the given value and return an Observable of it */
  private toObservable<T>(
    value: T | Promise<T> | Observable<T>
  ): Observable<T> {
    // Returns the given observable
    if (isObservable(value)) {
      return value as Observable<T>;
    }

    // Converts the Promise into observable
    if (Promise.resolve(value) == value) {
      return from(value as Promise<T>);
    }

    // Converts the value into observable
    return of(value as T);
  }
}
