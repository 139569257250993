import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserModel } from '@qtek/shared/models';

export const AuthUserActions = createActionGroup({
  source: 'Auth User',
  events: {
    Logout: props<{ queryParams?: string; skipRedirect?: boolean }>(),
    'Get User': emptyProps(),
    'Get User Success': props<{ user: UserModel }>(),
    'Get User Failure': props<{ error: Error }>(),
  },
});
