import { Inject, Injectable, Optional } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import {
  LocalStorageActions,
  LocalStorageManagementFeature,
} from '@qtek/core/local-storage-management';
import {
  isQueryWS,
  isQueryWSResponse,
  passWhenAlive,
  QueryWebsocketMessageRequest,
  SubWebsocketMessageRequest,
  UnsubWebsocketMessageRequest,
  WebSocketService,
} from '@qtek/core/websockets-core';
import { Language } from '@qtek/shared/models';
import { isNonNullable, isNullable } from '@qtek/shared/utils';
import {
  asapScheduler,
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  Observable,
  of,
  repeat,
  retry,
  switchMap,
  take,
  tap,
  toArray,
} from 'rxjs';

import {
  DebugTranslationsDI,
  LanguagesArray,
  passWhenLocalStorageLoaded,
  TranslationCoreState,
} from '../models/translation-core.model';
import { TranslationCoreActions } from '../store/translation-core.actions';
import { TranslationCoreFeature } from '../store/translation-core.reducer';

// export function isNonNullable<T>(value: T | undefined | null): value is T {
//   return value !== null && value !== undefined;
// }
//
// export function isNullable<T>(value: T | undefined | null): value is undefined | null {
//   return value === null || value === undefined;
// }

const subTranslation: SubWebsocketMessageRequest = {
  op: 'sub',
  ent: 'i18n',
};

const unsubTranslation: UnsubWebsocketMessageRequest = {
  op: 'unsub',
  ent: 'i18n',
};

@Injectable({
  providedIn: 'root',
})
export class TranslationCoreService {
  private readonly isLocalStorageLoadedSubject = new BehaviorSubject(false);
  private readonly isLocalStorageLoaded$ =
    this.isLocalStorageLoadedSubject.asObservable();
  private readonly cachedTranslationsSubject = new BehaviorSubject<
    Record<Language, Record<string, string>> | undefined
  >(undefined);
  private readonly cachedTranslations$ = this.cachedTranslationsSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  private readonly cachedLangSubject = new BehaviorSubject<
    Language | undefined
  >(undefined);
  private readonly cachedLang$ = this.cachedLangSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  private readonly languagesSubject = new BehaviorSubject(LanguagesArray);
  private readonly languages$ = this.languagesSubject.asObservable();

  get selectedLang(): Language {
    return this.getCachedLanguage() ?? 'en_US';
  }

  selectedLang$ = this.getCachedLanguageSubject();

  private cachedTranslationsSubscription = this.store
    .select(TranslationCoreFeature.selectTranslations)
    .pipe(
      distinctUntilChanged(),
      tap(translations => {
        this.cachedTranslationsSubject.next(translations);
      }),
      takeUntilDestroyed()
    )
    .subscribe();

  private cachedLanguageSubscription = this.store
    .select(TranslationCoreFeature.selectLang)
    .pipe(
      distinctUntilChanged(),
      tap(lang => {
        this.cachedLangSubject.next(lang);
      }),
      takeUntilDestroyed()
    )
    .subscribe();

  private webSocketSubscription = this.webSocketService
    .getWebSocketIsAlive$()
    .pipe(
      filter(isAlive => isAlive),
      mergeMap(() => {
        return this.webSocketService.getWebSocketSubject().pipe(
          mergeMap(socket =>
            socket.multiplex(
              () => subTranslation,
              () => unsubTranslation,
              message => message.ent === 'i18n'
            )
          )
        );
      }),
      tap(message => {
        if (isQueryWS(message) && isQueryWSResponse(message)) {
          const { sts, res } = message;
          this.store.dispatch(
            TranslationCoreActions.getTranslationsSuccess({
              translations: res,
              lang: sts['meta'].lng,
            })
          );
        }
      }),
      repeat({ delay: 1000 }),
      retry({ delay: 1000 }),
      takeUntilDestroyed()
    )
    .subscribe();

  private readonly localStorageTranslations$ = this.store
    .select(LocalStorageManagementFeature.selectI18n)
    .pipe(
      distinctUntilChanged(),
      map<
        string,
        {
          lang: Language;
          translations: Record<Language, Record<string, string>>;
        }
      >(translations => JSON.parse(translations ?? '{}'))
    );

  private readonly localStorageTranslationsSubscription =
    this.localStorageTranslations$
      .pipe(
        tap(({ translations, lang }) => {
          this.store.dispatch(
            TranslationCoreActions.getCachedTranslationsSuccess({
              translations,
            })
          );
          this.store.dispatch(
            TranslationCoreActions.setLanguage({ language: lang })
          );
          this.isLocalStorageLoadedSubject.next(true);
        }),
        repeat({ delay: 1000 }),
        retry({ delay: 1000 }),
        takeUntilDestroyed()
      )
      .subscribe();

  readonly webSocketIsAlive$ = this.webSocketService.getWebSocketIsAlive$();

  constructor(
    private webSocketService: WebSocketService,
    private store: Store,
    @Optional() @Inject(DebugTranslationsDI) private debugTranslation: boolean
  ) {}

  public setLanguage(lang: Language) {
    this.store.dispatch(TranslationCoreActions.setLanguage({ language: lang }));
  }

  getTranslations(keys: string[], lang?: Language): Observable<any> {
    return this.webSocketIsAlive$.pipe(
      switchMap(() => {
        this.fetchTranslations([{ keys, lang }]);

        return of(lang ?? this.selectedLang).pipe(
          mergeMap(lang =>
            of(keys).pipe(
              mergeMap(keys => keys),
              mergeMap(key => this.getTranslation(key, { lang }))
            )
          ),
          toArray(),
          map(translations =>
            keys.reduce((acc, key, index) => {
              acc[key] = translations[index];
              return acc;
            }, {} as any)
          )
        );
      })
    );
  }

  fetchTranslations(translations: { keys: string[]; lang?: Language }[]) {
    const langs = translations.reduce((prevLangs, currentTranslationReq) => {
      if (
        currentTranslationReq.lang &&
        !prevLangs.includes(currentTranslationReq.lang)
      ) {
        return [...prevLangs, currentTranslationReq.lang];
      }
      return prevLangs;
    }, []);

    const prms = langs.map(lang => {
      return {
        lng: lang,
        ids: translations
          .filter(translation => translation.lang === lang)
          .map(translation => translation.keys)
          .reduce((prevValue, currentValue) => prevValue.concat(currentValue))
          .toString(),
      };
    });

    prms.forEach(param => {
      const i18nMessage: QueryWebsocketMessageRequest = {
        op: 'query',
        ent: 'i18n',
        prms: param,
      };

      this.webSocketIsAlive$
        .pipe(
          filter(isAlive => isAlive),
          take(1),
          mergeMap(message =>
            this.webSocketService.getWebSocketSubject().pipe(
              mergeMap(socket => {
                socket.next(i18nMessage);

                return of(true);
              })
            )
          ),
          take(1)
        )
        .subscribe();
    });
  }

  getTranslation(
    key: string,
    options?: { lang?: Language; params?: Array<any> }
  ): Observable<string> {
    // console.log('start translating', moment().format('HH:mm:ss:SSS'), key);
    return combineLatest([
      this.store.select(TranslationCoreFeature.selectTranslations),
      this.store.select(TranslationCoreFeature.selectLang),
    ]).pipe(
      passWhenLocalStorageLoaded(this.isLocalStorageLoaded$),
      take(1),
      // tap((req) => console.log(req)),
      mergeMap(([translations, lang]) => {
        lang = options?.lang ?? lang ?? this.getUserBrowserLanguageCode();
        const requestedTranslation =
          translations?.[options?.lang ?? lang]?.[key];

        if (isNullable(requestedTranslation)) {
          const isAlive$ = this.webSocketService.getWebSocketIsAlive$();

          of({ key })
            .pipe(
              passWhenAlive(isAlive$),
              tap(() => {
                asapScheduler.schedule(() =>
                  this.store.dispatch(
                    TranslationCoreActions.getTranslations({
                      keys: [key],
                      lang: options?.lang ?? lang,
                    })
                  )
                );
              })
              // take(1)
            )
            .subscribe();
        }

        return of(requestedTranslation);
      }),
      distinctUntilChanged(),
      mergeMap(requestedTranslation => {
        return combineLatest([
          this.cachedTranslations$,
          this.store.select(TranslationCoreFeature.selectLang),
        ]).pipe(
          filter(
            ([translations, lang]) =>
              !!translations?.[options?.lang ?? lang]?.[key]
          ),
          map(
            ([translations, lang]) =>
              translations?.[options?.lang ?? lang]?.[key]
          ),
          take(1),
          map(expression => this.interpolate(expression, options?.params))
          // tap(() => {
          //   console.log('end translating', moment().format('HH:mm:ss:SSS'), key);
          // })
        );
      })
    );
  }

  saveTranslationToCache(): void {
    combineLatest([
      this.store.select(TranslationCoreFeature.selectTranslations),
      this.store.select(TranslationCoreFeature.selectLang),
      this.store.select(LocalStorageManagementFeature.selectI18n),
    ])
      .pipe(
        passWhenLocalStorageLoaded(this.isLocalStorageLoaded$),
        take(1),
        tap(([translations, lang, currentLocalStorage]) => {
          if (isNullable(translations)) {
            return;
          }

          if (isNullable(lang)) {
            lang = this.getUserBrowserLanguageCode();
          }

          if (isNullable(currentLocalStorage) && isNonNullable(translations)) {
            this.store.dispatch(
              LocalStorageActions.saveToLocalStorageByKey({
                key: 'i18n',
                value: JSON.stringify({ lang, translations: translations }),
              })
            );

            return;
          }

          const langKeys = LanguagesArray;

          const localStorageTranslations = JSON.parse(
            currentLocalStorage
          ) as TranslationCoreState;

          const newTranslation = langKeys.reduce(
            (previousValue, currentValue) => {
              return {
                ...previousValue,
                [currentValue]: {
                  ...(localStorageTranslations?.translations &&
                  localStorageTranslations.translations[currentValue]
                    ? localStorageTranslations.translations[currentValue]
                    : {}),
                  ...(translations && translations[currentValue]
                    ? translations[currentValue]
                    : {}),
                },
              };
            },
            {} as Record<Language, Record<string, string>>
          );

          this.store.dispatch(
            LocalStorageActions.saveToLocalStorageByKey({
              key: 'i18n',
              value: JSON.stringify({ lang, translations: newTranslation }),
            })
          );
        })
      )
      .subscribe();
  }

  getTranslationFromCache(
    key: string,
    options: { lang?: Language; params?: Array<any> }
  ): Observable<string> {
    return combineLatest([
      this.cachedTranslations$,
      this.store.select(TranslationCoreFeature.selectLang),
    ]).pipe(
      filter(
        ([translations, lang]) => !!translations?.[options?.lang ?? lang]?.[key]
      ),
      map(
        ([translations, lang]) => translations?.[options?.lang ?? lang]?.[key]
      ),
      take(1),
      map(expression => this.interpolate(expression, options?.params))
    );
  }

  getCachedTranslations():
    | Record<'en_US' | 'es_US' | 'pl_PL', Record<string, string>>
    | undefined {
    return this.cachedTranslationsSubject.getValue();
  }

  getCachedLanguage(): 'en_US' | 'es_US' | 'pl_PL' | undefined {
    return this.cachedLangSubject.getValue();
  }

  getCachedTranslationsSubject(): BehaviorSubject<
    Record<'en_US' | 'es_US' | 'pl_PL', Record<string, string>> | undefined
  > {
    return this.cachedTranslationsSubject;
  }

  getCachedLanguageSubject(): BehaviorSubject<
    'en_US' | 'es_US' | 'pl_PL' | undefined
  > {
    return this.cachedLangSubject;
  }

  getLanguages$(): Observable<Language[]> {
    return this.languages$;
  }

  instant(key: string, params?: Array<any>): string {
    const translation = this.interpolate(
      this.cachedTranslationsSubject.getValue()[
        this.getCachedLanguageSubject().getValue()
      ][key],
      params
    );

    const noTranslation = this.debugTranslation ? key : '';

    return translation || noTranslation;
  }

  private interpolate(expression: string, params?: Array<any>): string {
    if (typeof expression !== 'string' || !params || !Array.isArray(params)) {
      return expression;
    }

    return params.reduce(
      (message, value, index) => message.replace(`{${index + 1}}`, value),
      expression
    );
  }

  setLanguageFromBrowser() {
    const languageCode = this.getUserBrowserLanguageCode();
    return this.setLanguage(languageCode);
  }

  getUserBrowserLanguageCode(): Language {
    const userLang = navigator.language;
    const code = userLang[0] + userLang[1];
    let languageCode: Language = 'en_US';

    switch (code) {
      case 'pl': {
        languageCode = 'pl_PL';
        break;
      }
      case 'es': {
        languageCode = 'es_US';
        break;
      }
      default:
        break;
    }
    return languageCode;
  }
}
