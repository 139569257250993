import { NgModule } from '@angular/core';
import { ApiCoreModule } from '@qtek/core/api-core';
import { AuthUserModule } from '@qtek/libs/auth-user';
import { MetaCoreModule } from '@qtek/libs/meta-core';

@NgModule({
  imports: [AuthUserModule, MetaCoreModule, ApiCoreModule],
  providers: [],
})
export class WebsocketsCoreModule {}
