import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Route, RouterModule } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { ApiCoreModule } from '@qtek/core/api-core';
import { SnackBarCoreModule } from '@qtek/libs/snack-bar-core';
import { TranslationCoreModule } from '@qtek/libs/translation-core';
import { QtNetworkErrorComponent } from '@qtek/libs/ui';
import {
  AutoFocusDirective,
  DisableAfterClickDirective,
  PortalTemplateDirective,
  SubmitIfValidDirective,
} from '@qtek/shared/directives';
import { ExternalLoginButtonComponent } from './components/external-login-button/external-login-button.component';
import { LoginEmailComponent } from './components/login-email/login-email.component';
import { LoginPasswordComponent } from './components/login-password/login-password.component';
import { LoginRegisterComponent } from './components/login-register/login-register.component';
import { LoginResetPasswordConfirmComponent } from './components/login-reset-password-confirm/login-reset-password-confirm.component';
import { LoginResetPasswordComponent } from './components/login-reset-password/login-reset-password.component';
import { MfaLoginComponent } from './containers/mfa-login/mfa-login.component';
import { MfaCodeDialogComponent } from './dialogs/mfa-code-dialog/mfa-code-dialog.component';
import { MfaLoginEffects } from './store/mfa-login.effects';
import { MfaLoginFeature } from './store/mfa-login.reducer';

const routes: Route[] = [
  {
    path: '',
    component: MfaLoginComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    TranslationCoreModule,
    ApiCoreModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    SubmitIfValidDirective,
    MatCheckboxModule,
    MatDialogModule,
    MatSelectModule,
    PortalModule,
    MatIconModule,
    MatTooltipModule,
    SnackBarCoreModule,
    PortalTemplateDirective,
    AutoFocusDirective,
    DisableAfterClickDirective,
    MfaCodeDialogComponent,
    QtNetworkErrorComponent,
  ],
  declarations: [
    LoginEmailComponent,
    MfaLoginComponent,
    LoginPasswordComponent,
    LoginResetPasswordComponent,
    LoginRegisterComponent,
    ExternalLoginButtonComponent,
    LoginResetPasswordConfirmComponent,
  ],
  providers: [provideEffects(MfaLoginEffects), provideState(MfaLoginFeature)],

  exports: [MfaLoginComponent, ExternalLoginButtonComponent],
})
export class MfaLoginModule {}
