import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  BehaviorSubject,
  filter,
  Observable,
  Subject,
  Subscription,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { MfaCodeDialogComponent } from '../../dialogs/mfa-code-dialog/mfa-code-dialog.component';
import { LoginSteps, MfaCodeSubmit } from '../../models';

@Component({
  selector: 'qt-login-password',
  templateUrl: './login-password.component.html',
  styleUrls: ['./login-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPasswordComponent implements OnChanges, OnDestroy {
  @Input() email: string;
  @Input() validationError:
    | { errorTranslationKey: string | undefined; random: number }
    | undefined;
  @Input() triggerMfaCode$: Observable<void> | undefined;
  @Input() stepSubject: Subject<LoginSteps> | undefined;

  @Output() lostPasswordClick = new EventEmitter<void>();
  @Output() submitClick = new EventEmitter<string>();
  @Output() submitMfaCodeClick = new EventEmitter<MfaCodeSubmit>();

  destroySubject = new Subject<void>();
  destroy$ = this.destroySubject.asObservable();

  showPassword = false;
  mfaDialogDestroySubject = new Subject<void>();
  mfaDialogDestroy$ = this.mfaDialogDestroySubject.asObservable();

  showMfaDialogSubscription: Subscription | null = null;
  showDialogSubject = new BehaviorSubject(false);
  showDialog$ = this.showDialogSubject.asObservable();

  form = this.formBuilder.group({
    email: this.formBuilder.control('', [Validators.required]),
    password: this.formBuilder.control('', [Validators.required]),
  });

  constructor(
    private formBuilder: FormBuilder,
    private matDialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['email']) {
      this.setFormValue(this.email);
    }

    if (changes['validationError']) {
      this.form.controls.password.updateValueAndValidity();
    }

    if (changes['triggerMfaCode$']) {
      this.showMfaDialogSubscription?.unsubscribe();
      this.showMfaDialogSubscription = this.triggerMfaCode$
        ?.pipe(
          switchMap(() => {
            this.showDialogSubject.next(true);
            return this.matDialog
              .open(MfaCodeDialogComponent, {
                width: '500px',
              })
              .afterClosed()
              .pipe(
                take(1),
                tap(() => this.showDialogSubject.next(false)),
                filter(Boolean),
                tap((res: MfaCodeSubmit) => this.submitMfaCodeClick.emit(res))
              );
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  handleBackButton(): void {
    this.stepSubject?.next(LoginSteps.EMAIL);
  }

  handleLostPassword(): void {
    this.lostPasswordClick.emit();
  }

  handleSubmit(): void {
    if (this.form.controls.password.valid) {
      this.submitClick.emit(this.form.controls.password.value);
    }
  }

  private setFormValue(email: string): void {
    this.form.controls.email.patchValue(email);
    this.form.controls.email.updateValueAndValidity();
  }
}
