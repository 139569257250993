import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslationCoreModule } from '@qtek/libs/translation-core';

const ERROR_COLLAPSE_DURATION = 150;

export type QtNetError = 'ws' | 'http' | 'offline';

@Component({
  selector: 'qt-network-error',
  templateUrl: './network-error.component.html',
  styleUrls: ['./network-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('collapse', [
      state(
        'false',
        style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })
      ),
      state('true', style({ height: '0', visibility: 'hidden', opacity: 0.1 })),
      transition(
        'false => true',
        animate(ERROR_COLLAPSE_DURATION + 'ms ease-in')
      ),
      transition(
        'true => false',
        animate(ERROR_COLLAPSE_DURATION + 'ms ease-out')
      ),
    ]),
  ],
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    MatIconModule,
    TranslationCoreModule,
  ],
})
export class QtNetworkErrorComponent {
  @HostBinding('@collapse') get getCollapsed(): boolean {
    return !this.error;
  }
  @Input() error: QtNetError | null = null;

  readonly ErrNetTitleText = 'Network problems';
  readonly ErrNetCommonText = 'We are experiencing network issues';
  readonly ErrNetWSText =
    'There are problems with WebSocket connection. Application will try to reconnect...';
  readonly ErrNetHttpText =
    'There are problems with HTTP connection. Application will try to reconnect...';
  readonly ErrNetOfflineText =
    'You are offline. Check your internet connection.';
}
