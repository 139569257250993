import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '@qtek/shared/utils';

@Component({
  selector: 'qt-login-email',
  templateUrl: './login-email.component.html',
  styleUrls: ['./login-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginEmailComponent implements OnChanges {
  @Input() email: string | null = null;
  @Input() emailIsEditable = true;
  @Input() name = '';
  @Input() token = '';
  @Input() promo = '';
  @Input() phn = '';
  @Input() ptl = '';
  @Output() submitClick = new EventEmitter<string>();
  @Output() lostPasswordlick = new EventEmitter<void>();

  form = this.formBuilder.group({
    email: this.formBuilder.control('', [
      Validators.required,
      CustomValidators.email,
    ]),
  });

  constructor(private formBuilder: FormBuilder) {}

  ngOnChanges(): void {
    this.setFormValue(this.email ?? '', this.emailIsEditable);
  }

  handleLostPassword(): void {
    this.lostPasswordlick.emit();
  }

  handleSubmit(): void {
    this.form.controls.email.updateValueAndValidity();
    if (this.form.controls.email.valid || this.form.controls.email.disabled) {
      this.submitClick.emit(this.form.controls.email.getRawValue());
    }
  }

  private setFormValue(newValue: string, editable = true): void {
    this.form.controls.email.patchValue(newValue);
    editable
      ? this.form.controls.email.enable()
      : this.form.controls.email.disable();
  }
}
