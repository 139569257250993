import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { MetaResponse } from '@qtek/core/api-core';

export const MetaCoreActions = createActionGroup({
  source: 'metaCoreActions',
  events: {
    'Load Meta': props<{ params?: string[] }>(),
    'Load Meta Success': props<{ meta: Partial<MetaResponse> }>(),
    'Load Meta Error': props<{ error: Error }>(),
    //TODO It's used in sales settings component which isn't used in app so I commented the actions
    // 'Load Meta Country': props<{ country: string }>(),
    // 'Load Meta Country Success': props<{ countryInfo: any }>(),
    // 'Load Meta Country Error': props<{ error: Error }>(),
    // Those without success call Load Meta Success
    'Load Meta Timezones': emptyProps(),
    // 'Load Meta Countries': emptyProps(),
    // 'Load Meta Units': emptyProps(),
    // 'Load Meta Frequencies': emptyProps(),
    'Load Meta Roles': emptyProps(),
    'Load Meta Languages': emptyProps(),
    'Load Meta Title': emptyProps(),
    'Load Meta Mobile Title': emptyProps(),
    'Load Meta Gui Domain': emptyProps(),
    'Load Meta Gui Online Book Domain': emptyProps(),
    'Load Meta Gui Shr Domain': emptyProps(),
    'Load Meta Auth Gui Domain': emptyProps(),
    'Load Meta Doc Gui Domain': emptyProps(),
    'Load Meta Phones Country Codes': emptyProps(),
    'Load Meta Websocket': emptyProps(),
    'Load Meta Colors': emptyProps(),
    // 'Load Meta Currency': emptyProps(),
    // 'Load Meta Company License': emptyProps(),
    // 'Load Meta States': emptyProps(),
  },
});
