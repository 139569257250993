import { Route } from '@angular/router';
import { MfaLoginModule } from '@qtek/feature/mfa-login';
import { AppComponent } from './app.component';

export const appRoutes: Route[] = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: 'error',
        loadChildren: () =>
          import('@qtek/feature/error').then(m => m.ErrorModule),
      },
      {
        path: '',
        loadChildren: () => MfaLoginModule,
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
      },
    ],
  },
];
