<form novalidate class="login-email__container" [formGroup]="form">
  <div class="login-email__external-login-container">
    <qt-external-login-button
      type="ms"
      [promoCode]="promo"
      [ptl]="ptl"
      [phone]="phn"></qt-external-login-button>
    <qt-external-login-button
      type="sf"
      [promoCode]="promo"
      [ptl]="ptl"
      [phone]="phn"
      [token]="token"></qt-external-login-button>
  </div>

  <div class="login-email__login-hint">
    <em>{{ 'GNR_LOGIN_WITH_EMAIL' | translate }}</em>
  </div>

  <mat-form-field>
    <mat-label>{{ 'GNR_EMAIL_ADR' | translate }}</mat-label>
    <input
      matInput
      qtAutoFocus
      autocapitalize="none"
      type="email"
      formControlName="email" />
    <mat-error *ngIf="form.controls.email.errors?.['required']">
      {{ 'ERR_FIELD_REQUIRED' | translate }}
    </mat-error>
    <mat-error *ngIf="form.controls.email.errors?.['email']">
      {{ 'ERR_WRONG_EMAIL' | translate }}
    </mat-error>
  </mat-form-field>

  <button
    qtDisableAfterClick
    mat-raised-button
    color="accent"
    type="button"
    [qtSubmitIfValid]="form"
    (qtSubmitIfValid)="handleSubmit()"
    data-testid="continue-button">
    {{ 'GNR_CONTINUE' | translate }}
  </button>
</form>

<ng-template #portal [qtPortalTemplate]="'header'">
  <span class="login-email__header-text">{{ 'CMD_LOGIN' | translate }}</span>
</ng-template>
