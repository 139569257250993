import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AccessToken,
  BackendResponse,
  TokenAction,
  UserModel,
} from '@qtek/shared/models';
import { catchError, Observable, of } from 'rxjs';
import { BEResponse, HTTP_BASE_URL } from '../models/api-core.models';

@Injectable({
  providedIn: 'root',
})
export class ApiCoreService {
  getMetaData<T>(httpParams: HttpParams) {
    return this.httpClient
      .get<BEResponse<T>>(this.httpBaseUrl + '/api/v1/service/meta', {
        params: httpParams,
      })
      .pipe(catchError(({ error }) => of(error)));
  }

  login(payload: {
    email: string;
    passwd: string;
    mfa_code?: string;
    mfa_trust?: boolean;
  }) {
    return this.httpClient
      .post<BEResponse<unknown>>(
        this.httpBaseUrl + '/api/v1/service/auth',
        payload,
        {
          withCredentials: true,
        }
      )
      .pipe(catchError(({ error }) => of(error)));
  }

  logout() {
    return this.httpClient.get(this.httpBaseUrl + '/api/v1/service/logout', {
      withCredentials: true,
    });
  }

  resetPassword(payload: { email: string }) {
    return this.httpClient
      .post<BEResponse<unknown>>(
        this.httpBaseUrl + '/api/v1/service/rstinit',
        payload
      )
      .pipe(catchError(({ error }) => of(error)));
  }

  resetPasswordConfirm(payload: {
    email: string;
    pass1: string;
    pass2: string;
    rstToken: string;
  }) {
    return this.httpClient
      .post<BEResponse<unknown>>(
        this.httpBaseUrl + '/api/v1/service/rst',
        payload
      )
      .pipe(catchError(({ error }) => of(error)));
  }

  register<T>(payload: {
    email: string;
    passwd: string;
    lng: string;
    gdprFlg: boolean;
    tkn: string;
  }) {
    return this.httpClient.post<BEResponse<T>>(
      this.httpBaseUrl + '/api/v1/service/prs',
      payload
    );
  }

  getWsSessionInitToken() {
    return this.httpClient.get<BEResponse<{ wsTkn: string }>>(
      '/api/v1/service/wsTkn',
      {
        withCredentials: true,
      }
    );
  }

  getUserIsLogged<T>(path?: string) {
    return this.httpClient.get<BEResponse<T>>(
      `${path ? path : ''}/api/v1/service/usr?_v=auth`,
      {
        withCredentials: true,
      }
    );
  }

  postUserData(data: { theme: string }) {
    return this.httpClient.post<BEResponse<unknown>>('/api/v1/e/usr', data, {
      withCredentials: true,
    });
  }

  getUserData() {
    return this.httpClient.get<BEResponse<UserModel>>('/api/v1/service/usr', {
      withCredentials: true,
    });
  }

  getDocumentToken(
    docId: string,
    refId: string
  ): Observable<BackendResponse<AccessToken>> {
    return this.httpClient.get<BackendResponse<AccessToken>>(
      '/api/v1/service/doc/tkn/' + docId,
      {
        withCredentials: true,
        params: { refId, action: TokenAction.DOWNLOAD },
      }
    );
  }

  constructor(
    @Inject(HTTP_BASE_URL) private httpBaseUrl: string,
    private httpClient: HttpClient
  ) {}
}
