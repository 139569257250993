import { RelationGroup } from '@qtek/core/api-core';
import { LanguageOption } from '@qtek/shared/models';

export const META_CORE_KEY = 'MetaCoreKey';

export interface Role {
  id: string;
  name: string;
}

export interface Unit {
  id: string;
  name: string;
}

export interface Frequency {
  id: string;
  name: string;
}

export interface CountryCode {
  cur?: string;
  id: string;
  cd: string;
  cid?: string;
  pid?: string;
  mask?: string;
}

export interface Timezone {
  timezone: string;
  names: string[];
}

export interface Country {
  id: string;
  nm: string;
}

export interface Color {
  background: string;
  font: string;
}

export interface MetaCoreState {
  roles: {
    user: Role[];
    //TODO Probably not used in old app
    vendor: Role[];
    //TODO Probably not used in old app
    customer: Role[];
    staff: Role[];
  };
  //TODO Probably not used in old app - commented out
  //units: Unit[];
  // frequencies: Frequency[];
  languages: LanguageOption[];
  title1: string;
  title2: string;
  countryCode: CountryCode[];
  guiShrDomain: string;
  guiDomain: string;
  guiOnlineBookDomain: string;
  authGuiDomain: string;
  docGuiDomain: string;
  wsPingPong: number;
  // licCmp: string;
  countries: Country[];
  colors: Record<string, Color>;
  // countryInfo: any;
  //TODO Probably not used in old app - commented out
  //states: Record<string, string[]>;
  // licWebId: string;
  // powerSearchMeta: Record<string, any>;
  error: Error | null;
  //TODO ask Radek if this is really used
  token: string;
  //TODO think if with new approach to keep tokens in cookies, also change this to use cookie instead of localStorage
  // darkTheme?: boolean;
  // curs?: string[];
  // timezones?: Timezone[];
}

export const metaCoreInitialState: MetaCoreState = {
  // curs: [],
  title1: '',
  title2: '',
  countries: [],
  // countryInfo: null,
  error: null,
  // licCmp: '',
  guiDomain: '',
  // licWebId: '',
  guiShrDomain: '',
  wsPingPong: null,
  countryCode: [],
  // frequencies: [],
  languages: [],
  guiOnlineBookDomain: '',
  authGuiDomain: '',
  docGuiDomain: '',
  roles: {
    user: [],
    staff: [],
    vendor: [],
    customer: [],
  },
  colors: {},
  // timezones: [],
  token: '',
};

export const mapRoleToName = (role: RelationGroup): string => {
  switch (role) {
    case RelationGroup.USER:
      return 'user';
    case RelationGroup.VENDOR:
      return 'vendor';
    case RelationGroup.CUSTOMER:
      return 'customer';
    case RelationGroup.STAFF:
      return 'staff';
    default:
      return '';
  }
};
