import { DOCUMENT } from '@angular/common';
import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { HTTP_BASE_URL } from '@qtek/core/api-core';
import { TranslationCoreFeature } from '@qtek/libs/translation-core';
import { getGMTOffset } from '@qtek/shared/utils';
import * as momentTimezone from 'moment-timezone';
import { take, tap } from 'rxjs';

@Component({
  selector: 'qt-external-login-button',
  templateUrl: './external-login-button.component.html',
  styleUrls: ['./external-login-button.component.scss'],
})
export class ExternalLoginButtonComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private store: Store,
    @Inject(DOCUMENT) private document: Document,
    @Inject(forwardRef(() => HTTP_BASE_URL)) private baseUrl: string
  ) {}

  rid: string;
  iconName: string;
  buttonText: string;

  @Input() type: 'gl' | 'ms' | 'clv' | 'sf';
  // @Input() calendarConnect: boolean;
  // @Input() invalid: boolean;
  // @Input() customText: string;
  // @Input() customLink: string;
  // @Input() customTarget: string;
  // @Input() customBookingTarget: string;
  // @Input() webId: string;
  @Input() ptl: string;
  // @Input() connectApproval: boolean;
  // @Input() newWindow: boolean;
  // @Input() darkMode: boolean;
  @Input() promoCode: string;
  @Input() phone: string;
  @Input() token: string;
  // @Input() customBaseLink: string;
  @Input() isRegistering = false;

  public ngOnInit(): void {
    this.rid = this.route.snapshot.queryParams['rid'];

    switch (this.type) {
      case 'ms':
        this.iconName = 'ms_logo';
        // if (this.calendarConnect) {
        //   this.buttonText = 'FTR_INTEGRATIONS_CONNECT_MICROSOFT';
        // } else {
        this.buttonText = this.isRegistering
          ? 'GNR_REGISTER_WITH_MICROSOFT'
          : 'GNR_LOGIN_WITH_MICROSOFT';
        // }
        break;
      case 'gl':
        this.iconName = 'google_logo';
        // if (this.calendarConnect) {
        //   this.buttonText = 'FTR_INTEGRATIONS_CONNECT_GOOGLE';
        // } else {
        this.buttonText = this.isRegistering
          ? 'GNR_REGISTER_WITH_GOOGLE'
          : 'GNR_LOGIN_WITH_GOOGLE';
        // }
        break;
      case 'clv':
        this.iconName = 'clover_logo';
        // if (this.calendarConnect) {
        //   this.buttonText = 'GNR_CONNECT_WITH_CLOVER';
        // } else {
        this.buttonText = this.isRegistering
          ? 'GNR_REGISTER_WITH_CLOVER'
          : 'GNR_LOGIN_WITH_CLOVER';
        // }
        break;
      case 'sf':
        this.iconName = 'sf_logo';
        // if (this.calendarConnect) {
        //   this.buttonText = 'GNR_CONNECT_WITH_SALESFORCE';
        // } else {
        this.buttonText = this.isRegistering
          ? 'GNR_REGISTER_WITH_SALESFORCE'
          : 'GNR_LOGIN_WITH_SALESFORCE';
        // }
        break;
      default:
    }

    // if (this.customText) {
    //   this.buttonText = this.customText;
    // }
  }

  login(): void {
    this.store
      .select(TranslationCoreFeature.selectLang)
      .pipe(
        tap(currentLang => {
          const returnUrl = btoa(
            decodeURIComponent(
              this.route.snapshot.queryParams['returnUrl'] || ''
            )
          );
          let returnUrlParam = '';

          // if (this.customBookingTarget) {
          //   returnUrlParam = `&_trg=booking_${this.customBookingTarget}`;
          // } else if (this.customTarget) {
          //   returnUrlParam = `&_trg=${this.customTarget}`;
          // } else
          if (returnUrl) {
            returnUrlParam = `&_trg=fe_${returnUrl}`;
          }

          const timezoneGuess =
            Intl && Intl.DateTimeFormat
              ? `&tzGuess=${btoa(momentTimezone.tz.guess())}`
              : '';
          const rid = this.rid ? `&rid=${this.rid}` : '';
          const gmtOffset = `&gmtOffset=${getGMTOffset()}`;
          // const webId = this.webId ? `&webId=${this.webId}` : '';
          const promo = this.promoCode ? `&promo=${this.promoCode}` : '';
          const phone = this.phone ? `&phn=${this.phone}` : '';
          const token = this.token ? `&t=${this.token}` : '';
          const portal = this.ptl ? `&ptl=${this.ptl}` : '';
          // this.customLink ||
          // `${this.baseUrl}/api/v1/vnd/oauth/${this.type}/init?lng=${currentLang}${gmtOffset}${returnUrlParam}${timezoneGuess}${rid}${webId}${promo}${token}${phone}${portal}`;

          // if (this.newWindow) {
          //   const w = window.open(url, '_blank');
          //   w.focus();
          // } else {
          this.document.location.href = `${this.baseUrl}/api/v1/vnd/oauth/${this.type}/init?lng=${currentLang}${gmtOffset}${returnUrlParam}${timezoneGuess}${rid}${promo}${token}${phone}${portal}`;
          // }
        }),
        take(1)
      )
      .subscribe();
  }

  connect(): void {
    let setupSublink: string;

    switch (this.type) {
      case 'ms':
        setupSublink = 'microsoft';
        break;
      case 'gl':
        setupSublink = 'google';
        break;
      case 'clv':
        setupSublink = 'clover';
        break;
      case 'sf':
        setupSublink = 'sf';
    }

    const promo = this.promoCode ? `&promo=${this.promoCode}` : '';
    const token = this.token ? `&t=${this.token}` : '';
    const portal = this.ptl ? `&ptl=${this.ptl}` : '';

    // this.document.location.href = `${this.baseUrl}/api/v1/vnd/oauth/${this.type}/${
    //   this.connectApproval ? 'calInitAP' : this.type === 'clv' ? 'init' : 'calInit'
    // }?_trg=fe_${btoa('/stp/' + setupSublink)}${promo}${token}${portal}`;
    this.document.location.href = `${this.baseUrl}/api/v1/vnd/oauth/${
      this.type
    }/${this.type === 'clv' ? 'init' : 'calInit'}?_trg=fe_${btoa(
      '/stp/' + setupSublink
    )}${promo}${token}${portal}`;
  }
}
