import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '@qtek/shared/utils';
import { Subject } from 'rxjs';
import { RegisterData } from '../../models';
import { PasswordValidators } from '../../utils/password-validator';

@Component({
  selector: 'qt-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginRegisterComponent implements OnChanges, OnDestroy {
  @Input() email: string | undefined;
  @Input() firstName: string | undefined;
  @Input() emailDisabled = false;
  @Input() termsAndPrivacy: { terms: string; privacy: string };
  @Input() token = '';
  @Input() promo = '';
  @Input() phn = '';
  @Input() ptl = '';
  @Output() submitClick = new EventEmitter<RegisterData>();

  readonly minPassLength = 8;

  form = this.formBuilder.group(
    {
      email: this.formBuilder.control('', [
        Validators.required,
        CustomValidators.email,
      ]),
      password: this.formBuilder.control('', [
        Validators.minLength(this.minPassLength),
        Validators.required,
        PasswordValidators.passwordDigitRequired,
        PasswordValidators.passwordLowercaseRequired,
        PasswordValidators.passwordUppercaseRequired,
        PasswordValidators.passwordSpecialCharacterRequired,
      ]),
      confirmPassword: this.formBuilder.control('', [Validators.required]),
      privacyAgreement: this.formBuilder.control(false, [
        Validators.requiredTrue,
      ]),
    },
    {
      validators: [
        PasswordValidators.confirmedPasswordValidator(
          'password',
          'confirmPassword'
        ),
      ],
    }
  );

  destroySubject = new Subject<void>();
  destroy$ = this.destroySubject.asObservable();

  showPassword = false;

  constructor(private formBuilder: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['email']) {
      this.setFormValue(this.email);
    }

    if (changes['emailDisabled']) {
      changes['emailDisabled'].currentValue
        ? this.form.controls['email'].disable()
        : this.form.controls['email'].enable();
    }
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  get isPasswordNotConfirmed(): boolean {
    return (
      this.form.controls['confirmPassword'].errors?.[
        'confirmedPasswordValidator'
      ] || this.form.controls['confirmPassword'].errors?.['required']
    );
  }

  handleSubmit(): void {
    if (this.form.valid) {
      this.submitClick.emit({
        email: this.form.controls['email'].value,
        password: this.form.controls['password'].value,
        gdprFlag: this.form.controls['privacyAgreement'].value,
      });
    }
  }

  private setFormValue(email: string): void {
    this.form.controls['email'].patchValue(email);
    this.form.controls['email'].updateValueAndValidity();
  }
}
