<form novalidate class="login-register__container" [formGroup]="form">
  <div class="login-register__login-hint">
    {{ 'CMD_PWD_FOR_EMAIL' | translate: [this.firstName ?? '', this.email] }}
  </div>

  <mat-form-field subscriptSizing="dynamic">
    <mat-label>{{ 'GNR_PASSWD' | translate }}</mat-label>
    <input
      matInput
      qtAutoFocus
      autocapitalize="none"
      [type]="showPassword ? 'text' : 'password'"
      formControlName="password" />
    <button mat-icon-button matSuffix (click)="toggleShowPassword()">
      <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
    </button>
  </mat-form-field>
  <div class="password-validator__wrapper">
    <div class="password-validator">
      <mat-icon
        *ngIf="
          form.controls['password'].errors?.['passwordDigitRequired'];
          else validIcon
        "
        class="invalid"
        >close</mat-icon
      >
      <span>{{ 'GNR_PASS_ONE_DIGIT' | translate }}</span>
    </div>
    <div class="password-validator">
      <mat-icon
        *ngIf="
          form.controls['password'].errors?.['minlength'] ||
            form.controls['password'].errors?.['required'];
          else validIcon
        "
        class="invalid"
        >close</mat-icon
      >
      <span>{{ 'GNR_PASS_MIN_LENGTH' | translate: [minPassLength + ''] }}</span>
    </div>
    <div class="password-validator">
      <mat-icon
        *ngIf="
          form.controls['password'].errors?.['passwordUppercaseRequired'];
          else validIcon
        "
        class="invalid"
        >close</mat-icon
      >
      <span>{{ 'GNR_PASS_ONE_UPPER' | translate }}</span>
    </div>
    <div class="password-validator">
      <mat-icon
        *ngIf="
          form.controls['password'].errors?.['passwordLowercaseRequired'];
          else validIcon
        "
        class="invalid"
        >close</mat-icon
      >
      <span>{{ 'GNR_PASS_ONE_LOWER' | translate }}</span>
    </div>
    <div class="password-validator">
      <mat-icon
        *ngIf="
          form.controls['password'].errors?.[
            'passwordSpecialCharacterRequired'
          ];
          else validIcon
        "
        class="invalid"
        >close</mat-icon
      >
      <span>{{ 'GNR_PASS_ONE_SPECIAL' | translate }}</span>
    </div>
  </div>

  <mat-form-field>
    <mat-label>{{ 'CMD_REENTR_PSW' | translate }}</mat-label>
    <input
      matInput
      autocapitalize="none"
      type="password"
      formControlName="confirmPassword" />
    <mat-icon
      matSuffix
      *ngIf="form.controls['confirmPassword'].touched"
      [ngClass]="isPasswordNotConfirmed ? 'invalid' : 'valid'">
      {{ isPasswordNotConfirmed ? 'close' : 'done' }}
    </mat-icon>
    <mat-error
      *ngIf="
        form.controls['confirmPassword'].touched &&
        form.controls['confirmPassword'].errors?.['required']
      ">
      {{ 'ERR_FIELD_REQUIRED' | translate }}
    </mat-error>
    <mat-error
      *ngIf="
        form.controls['confirmPassword'].touched && isPasswordNotConfirmed
      ">
      {{ 'ERR_USR_INV_PWDS' | translate }}
    </mat-error>
  </mat-form-field>

  <div class="login-register__agreement-container">
    <mat-checkbox formControlName="privacyAgreement">
      <div class="login-register__agreement-text-container">
        <span>{{ 'GNR_SYNPLY_RODO_DISCL' | translate }}</span>
        <span>
          <a
            mat-button
            class="login-register__link-button"
            target="_blank"
            [href]="termsAndPrivacy.privacy">
            {{ 'GNR_RODO_POLICY' | translate }}.
          </a>
          <span class="login-register__required">*</span>
        </span>
      </div>
    </mat-checkbox>
    <mat-error
      *ngIf="
        form.controls['privacyAgreement'].touched &&
        form.controls['privacyAgreement'].errors?.['required']
      ">
      {{ 'ERR_NOT_CHECKED' | translate }}
    </mat-error>
  </div>

  <button
    qtDisableAfterClick
    mat-raised-button
    color="accent"
    type="button"
    [disabled]="form.invalid"
    [qtSubmitIfValid]="form"
    (qtSubmitIfValid)="handleSubmit()">
    {{ 'GNR_CREATE_ACNT_REG' | translate }}
  </button>
</form>

<ng-template #validIcon>
  <mat-icon class="valid">done</mat-icon>
</ng-template>
