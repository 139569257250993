/** DO NOT EDIT THIS FILE! */
import { Version } from '@angular/core';
import { Bundle } from './models';

export const VERSION = new Version('0.0.0-PLACEHOLDER');

export const BUNDLE: Bundle = {
  date: null,
  commitSha: null,
  version: VERSION,
};
