import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslationCoreModule } from '@qtek/libs/translation-core';
import {
  AutoFocusDirective,
  SubmitIfValidDirective,
} from '@qtek/shared/directives';
import { MfaCodeSubmit } from '../../models';

@Component({
  selector: 'qt-mfa-code-dialog',
  templateUrl: 'mfa-code-dialog.component.html',
  styleUrls: ['mfa-code-dialog.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TranslationCoreModule,
    SubmitIfValidDirective,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    AutoFocusDirective,
  ],
})
export class MfaCodeDialogComponent {
  mfaForm = this.formBuilder.group({
    mfaCode: this.formBuilder.control('', [Validators.required]),
    saveMachine: this.formBuilder.control(false, []),
  });

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<MfaCodeDialogComponent>
  ) {}

  handleSubmitMfaCode(): void {
    if (this.mfaForm.controls.mfaCode.valid) {
      this.dialogRef.close({
        mfaCode: this.mfaForm.controls.mfaCode.value,
        saveDevice: this.mfaForm.controls.saveMachine.value,
      } as MfaCodeSubmit);
    }
  }
}
