import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiCoreService } from '@qtek/core/api-core';
import { AuthUserActions } from './auth-user.actions';
import { map, switchMap, tap } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MetaCoreFeature } from '@qtek/libs/meta-core';

@Injectable()
export class AuthUserEffects {
  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUserActions.logout),
        switchMap(({ queryParams, skipRedirect }) =>
          this.store.select(MetaCoreFeature.selectAuthGuiDomain).pipe(
            switchMap(authDomain =>
              this.apiCoreService.logout().pipe(
                tap(() => {
                  if (!skipRedirect) {
                    window.location.href = `${authDomain}/${
                      queryParams ? queryParams : ''
                    }`;
                  }
                })
              )
            )
          )
        )
      ),
    { dispatch: false }
  );

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthUserActions.getUser),
      switchMap(() =>
        this.apiCoreService.getUserData().pipe(
          map(response =>
            response.sts
              ? AuthUserActions.getUserFailure({
                  error: new Error('not logged'),
                })
              : AuthUserActions.getUserSuccess({ user: response.res })
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiCoreService: ApiCoreService,
    private router: Router,
    private store: Store
  ) {}
}
