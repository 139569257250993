import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Language } from '@qtek/shared/models';
import { Translation } from '../models/translation-core.model';

export const TranslationCoreActions = createActionGroup({
  source: 'Translation Core',
  events: {
    'Get Translations': props<{ keys: string[]; lang?: Language }>(),
    'Get Translations Success': props<{
      translations: Translation[];
      lang?: Language;
    }>(),
    'Get Translations Error': props<{ error: Error }>(),
    'Get Cached Translations': emptyProps(),
    'Get Cached Translations Success': props<{
      translations: Record<Language, Record<string, string>>;
    }>(),
    'Get Cached Translations Error': props<{ error: Error }>(),
    'Save Translations To Cache': emptyProps(),
    'Save Translations To Cache Success': emptyProps(),
    'Save Translations To Cache Error': props<{ error: Error }>(),
    'Set Language': props<{ language: Language }>(),
  },
});
