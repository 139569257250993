import {
  ChangeDetectorRef,
  Directive,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import { Subject, tap } from 'rxjs';
import { isNonNullable } from '@qtek/shared/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButton } from '@angular/material/button';

@Directive({
  selector: '[qtDisableAfterClick]',
  standalone: true,
})
export class DisableAfterClickDirective implements OnDestroy {
  @Input('qtDisableAfterClickDuration') duration = 500;

  private timeoutCallback: number = null;

  private disableSubject = new Subject<void>();
  private disableSubscription = this.disableSubject
    .asObservable()
    .pipe(
      tap(() => {
        clearTimeout(this.timeoutCallback);

        if (isNonNullable(this.matButton)) {
          this.matButton.disabled = true;
          this.changeDetectorRef.markForCheck();

          this.timeoutCallback = window.setTimeout(() => {
            this.matButton.disabled = false;
            this.changeDetectorRef.markForCheck();
          }, this.duration);
        }
      }),
      takeUntilDestroyed()
    )
    .subscribe();

  @HostListener('click')
  setDisabledState(): void {
    this.disableSubject.next();
  }

  constructor(
    private matButton: MatButton,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    clearTimeout(this.timeoutCallback);
  }
}
