import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '@qtek/shared/utils';
import { Subject } from 'rxjs';
import { LoginSteps } from '../../models';

@Component({
  selector: 'qt-login-reset-password',
  templateUrl: './login-reset-password.component.html',
  styleUrls: ['./login-reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginResetPasswordComponent implements OnChanges {
  @Input() email: string | null = null;
  @Input() stepSubject: Subject<LoginSteps> | undefined;
  @Output() submitClick = new EventEmitter<string>();

  form = this.formBuilder.group({
    email: this.formBuilder.control('', [
      Validators.required,
      CustomValidators.email,
    ]),
  });
  constructor(private formBuilder: FormBuilder) {}

  ngOnChanges(): void {
    this.setFormValue(this.email ?? '');
  }
  handleBackButton(): void {
    this.stepSubject.next(LoginSteps.EMAIL);
  }
  handleSubmit(): void {
    this.form.controls.email.updateValueAndValidity();

    if (this.form.controls.email.valid) {
      this.submitClick.emit(this.form.controls.email.getRawValue());
    }
  }

  private setFormValue(email: string): void {
    this.form.controls.email.patchValue(email);
    this.form.controls.email.updateValueAndValidity();
  }
}
